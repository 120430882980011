export const Links = {
  Unauthorized: {
    Home: '/',
    Login: '/login',
    Requisites: '/requisites',
    Privacy: '/privacy',
    UserRegistration: '/register',
    EmailConfirmation: '/confirm-email',
    PasswordForgotten: '/forgot-password',
    PasswordReset: '/reset-password',
  },
  Authorized: {
    Dashboard: '/',
    NotFound: '/not-found',
    Folder: '/folder/:folderId',
    Recipe: '/folder?/:folderId?/recipe/:recipeId',
    RecipeCalculations: 'calculations',
    RecipeInvestigation: 'investigation',
    ExportRecipes: '/export/:includeInvestigations/:folderId?',
    Materials: '/materials',
    Promocodes: '/promocodes',
    UserProfile: '/profile',
  },
};

type FolderRecipeParams = {
  folderId?: string;
  recipeId: string | number;
};

export const getRecipeRoute = ({ folderId, recipeId }: FolderRecipeParams) =>
  folderId
    ? `/folder/${folderId}/recipe/${recipeId}/calculations`
    : `/recipe/${recipeId}/calculations`;

export const getInvestigationRoute = ({ folderId, recipeId }: FolderRecipeParams) =>
  folderId
    ? `/folder/${folderId}/recipe/${recipeId}/investigation`
    : `/recipe/${recipeId}/investigation`;

export const getFolderRoute = (folderId: string) => `/folder/${folderId}`;

export const WITH_INVESTIGATIONS = 'withInvestigations';
const addIncludeInvestiogationsRoot = (includeInvestigations: boolean) =>
  includeInvestigations ? WITH_INVESTIGATIONS : 'withoutInvestigations';

export const getExportFolderRoute = (includeInvestigations: boolean, folderId: string) =>
  `/export/${addIncludeInvestiogationsRoot(includeInvestigations)}/${folderId}`;

export const getExportRoute = (includeInvestigations: boolean) =>
  `/export/${addIncludeInvestiogationsRoot(includeInvestigations)}`;
